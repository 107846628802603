.QueriesGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.QueriesGrid span {
  color: var(--color-primary);
}

.QueriesGrid span:last-child {
  margin-bottom: 10px;
}

.ActionIcons {
  display: flex;
}

.ActionIcons svg {
  width: 20px;
}

.ActionIcons svg:hover {
  cursor: pointer;
}

.Separator {
  grid-column: 1 / 4;
  border-bottom: 1px solid var(--color-primary);
  margin: 10px 0;
}
